.button-theme {
    background: #2b3d58;
}

.button-theme:hover {
    background: #2c5ce4;
}

.dashboard-nav ul {
    border-bottom: 1px solid #2c5ce4;
}

.tab-box-3 .nav-link {
    border: solid 1px #2b3d58;
    color: #2b3d58;
}

.tab-box-3 .active{
    background-color: #2b3d58!important;
    border: solid 1px #2b3d58;
}

.tab-box-3 .active.a2 {
    background-color: transparent!important;
    border: solid 1px transparent;
}

.new-user-box .follow:hover{
    background: #2b3d58;
    color: #fff;
}

.advantages-box .icon {
    background: #2b3d58;
}

.service-info-2 .icon i{
    color: #2b3d58;
}

.counters-2{
    background: #2b3d58;
}

.pages-404-2 .btn, .pages-404 .btn:hover, .pages-404 .btn:active {
    background: #2b3d58;
}

.text .ratings-box {
    background: #2b3d58;
}

.setting-button i{
    color: #2b3d58;
}

.btn-white{
    background: #2b3d58;
    color: #fff;
}

.btn-white:hover{
    color: #fff;
    background: #2c5ce4;
}

.top-header {
    background-color: #2b3d58;
}

#page_scroller {
    background: #2b3d58;
}

.dashboard-nav{
    background: #2b3d58;
}

/** Tab box 2 start **/
.tab-box-2 .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background: transparent;
    border-bottom: solid 2px #2b3d58;
    border-radius: 0;
    color: #2b3d58;
}

.tab-box .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background: #2b3d58;
    border-radius: 0;
}

.checkbox-theme input[type="checkbox"]:checked + label::before {
    border: 2px solid #2b3d58;
}

.main-header .dropdown-menu a:hover {
    color: #2b3d58;
}

input[type=checkbox]:checked + label:before {
    color: #2b3d58;
}

.banner-bg-color{
    background: #2b3d58;
}

.btn-theme{
    background: #2b3d58;
    border: solid 1px #2b3d58;
}

.btn-theme:hover{
    background: #2c5ce4;
    border: solid 1px #2c5ce4;
}

.btn-white-lg-outline:hover {
    color: #FFF;
    background: #2b3d58;
    border: solid 2px #2b3d58;
}

.properties-amenities ul li i {
    color: #2b3d58
}

.pages-404 .btn {
    background: #2b3d58;
}

.pages-404 .btn:hover {
    background: #2c5ce4;
}

.border-button-theme {
    color: #2b3d58;
    border: 2px solid #2b3d58;
}

.banner-detail-box .red-box {
    /* background: #2b3d58 url(../../img/slider-detail.png) center center; */
}

.banner-detail-box .white-box .slide-price {
    color: #2b3d58;
}

.banner-detail-box .white-box .slide-location::after {
    background-color: #2b3d58;
}

.search-options-btn, .search-options-btn:hover {
    background: #2b3d58;
}

.search-button {
    background: #2b3d58;
}

.intro-section {
    background: #2b3d58;
}

.search-button:hover {
    color: #fff;
    background: #2c5ce4;
}

.popular .price-for-user {
    color: #2b3d58;
}

.dolar{
    color: #2b3d58;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
    color: #2b3d58;
}

.bootstrap-select .dropdown-menu > .active > a,
.bootstrap-select .dropdown-menu > .active > a:focus,
.bootstrap-select .dropdown-menu > .active > a:hover {
    color: #2b3d58;
}

.bootstrap-select .dropdown-menu li a:hover .text {
    color: #2b3d58;
}

.tag {
    background: #2b3d58;
}

.tag::after {
    border-left: 10px solid #2b3d58;
}

.recent-properties-box .detail header::after {
    background-color: #2b3d58;
}

.blog-1 .detail h3 a:hover{
    color: #2b3d58;
}

.blog-1 .date-box{
    background: #2b3d58;
}

.blog-1 .date-box::after {
    border-left: 15px solid #2b3d58;
}

.property-box .detail .title a {
    color: #2b3d58;
}

footer .footer-item .contact-info li label a:hover {
    color: #2b3d58;
}

.service-info .icon i{
    color: #2b3d58;
}

.service-box .heading .title h4 a:hover {
    color: #2b3d58;
}

.main-header .form-inline .submit {
    border: solid 1px #2b3d58;
    color: #2b3d58;
}

.main-header .form-inline .submit:hover {
    border: solid 1px #2b3d58;
    background: #2b3d58;
    color: #fff;
}

.main-header .navbar-expand-lg .navbar-nav .nav-link:hover {
    color: #2b3d58;
}

/*.main-header .navbar-expand-lg .navbar-nav .active .nav-link {
    color: #2b3d58 !important;
}*/

.main-header .navbar-expand-lg .user-account li a:hover {
    color: #2b3d58;
}

.plan .price-header h1 {
    color: #2b3d58;
}

.btn-outline {
    border: solid 1px #2b3d58;
    color: #2b3d58;
}

.btn-outline:hover {
    border: solid 1px #2c5ce4;
    background: #2c5ce4;
    color: #fff;
}

.btn-outline-2 {
    border: solid 2px #2b3d58;
    color: #2b3d58;
    font-weight: 600;
}

.heading-properties-2 h3 span {
    color: #2b3d58;
}

.btn-outline-2:hover {
    border: solid 2px #2c5ce4;
    background: #2c5ce4;
    color: #fff;
}

.footer .form-inline .btn{
    background: #2b3d58;
    color: #fff;
}

.search-box-2{
    background: #2b3d58;
}

.about-box .detail h3 {
    color: #2b3d58;
}

li.active.filtr-button.filtr a{
    color: #2b3d58;
}

.coming-soon-inner h1 span{
    color: #2b3d58;
}

.filteriz-navigation li a:hover{
    color: #2b3d58;
}

.sub-banner .page-info .breadcrumb-area ul li a:hover {
    color: #2b3d58;
}


.show-more-options i{
    color: #2b3d58;
}

.form-content-box .footer span a {
    color: #2b3d58;
}

.pagination > li > .active {
    background: #2b3d58;
}

.pagination > li > a:hover {
    background: #2b3d58;
    color: #fff;
}

.read-more {
    color: #2b3d58;
}

.blog-3 .date-box {
    background: #2b3d58;
}

.blog-3 .date-box::after {
    border-right: 15px solid #2b3d58;
}

.blog-3 .detail h3 a {
    color: #2b3d58;
}

.blog-3 .detail .post-meta span a i {
    color: #2b3d58;
}

.range-slider .ui-slider .ui-slider-handle {
    background: #2b3d58;
}

.our-agent-sidebar .carousel-indicators .active {
    background: #2b3d58;
}

.range-slider .ui-slider .ui-slider-handle {
    border: 2px solid #2b3d58;
}

.range-slider .ui-widget-header {
    background-color: #2b3d58;
}

.main-header .navbar-expand-lg .navbar-nav .link-color {
    color: #2b3d58;
    border: solid 1px #2b3d58;
}

.main-header .navbar-expand-lg .navbar-nav .link-color:hover{
    color: #fff;
    border: solid 1px #2b3d58;
    background: #2b3d58;
}

.posts-by-category ul li a:hover {
    color: #2b3d58;
}

.tags-box ul li a:hover {
    border: 1px solid #2b3d58;
    color: #2b3d58;
}

.latest-tweet a {
    color: #2b3d58;
}

.blog-2 h3 a:hover{
    color: #2b3d58;
}

.blog-tags a:hover {
    border: 1px solid #2b3d58;
    color: #2b3d58;
}

.blog-2 .date-box {
    background: #2b3d58;
}

.blog-2 .detail h4 a:hover{
    color: #2b3d58;
}

.price-box span{
    color: #fff;
    font-weight: 700;
}

.leaflet-container a.leaflet-popup-close-button {
   color: #2b3d58;
}

.breadcrumbs li a:hover {
    color: #2b3d58;
}

.helping-center ul li i {
    color: #2b3d58;
}

.my-account-box .title::after {
    background-color: #2b3d58;
}

.breadcrumb-nav ul .active {
    color: #2b3d58;
}

.photoUpload {
    background: #2b3d58;
}

.typography-page mark.color {
    background-color: #2b3d58;
}

.faq-accordion .card-header .collapsed.card-title:before {
    color: #2b3d58;
}

.manage-table .title-container h2 a {
    color: #2b3d58;
}

.manage-table .action a:hover {
    color: #2b3d58;
}

.comment-content .btn-1:hover{
    background: #2b3d58;
    color: #fff;
}

.dashboard-nav ul li span.nav-tag {
    color: #2b3d58;
}

.comment-content h6 span a{
    color: #2b3d58;
}

.comment-content h6 span a:hover{
    color: #2c5ce4;
}

.bg-active {
    background: #2b3d58;
    color: #fff !important;
}

.typography .list-3 li:before, .list-2 li:before, .list-1 li:before {
    color: #2b3d58;
}

.numbered.color.filled ol > li::before {
    border: 1px solid #2b3d58;
    background-color: #2b3d58;
}

.numbered.color ol > li::before {
    border: 1px solid #2b3d58;
    color: #2b3d58;
}

.widget-link:hover {
    color: #2b3d58;
}

.coming-soon-inner .btn, .coming-soon-inner .btn:hover, .coming-soon-inner .btn:active {
    background: #2b3d58;
}

.property-box-2 .detail .title a {
    color: #2b3d58;
}

.tabbing-box .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #2b3d58;
}

.tabbing-box .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:hover {
    background-color: #2b3d58;
}

.map-marker:hover {
    background-color: #2b3d58;
    cursor: pointer;
}

.map-marker:hover:before {
    border-color: #2b3d58 transparent transparent transparent;
}

.map-marker.featured:hover {
    background-color: #2b3d58;
}

.map-marker.featured:hover:before {
    border-color: #2b3d58 transparent transparent transparent;
}

.map-marker .icon {
    border: 3px solid #2b3d58;
}

.marker-active .map-marker {
    background-color: #2b3d58;
}

.marker-active .map-marker:before {
    border-color: #2b3d58 transparent transparent transparent;
}

.slick-arrow-buton:hover{
    background: #2b3d58;
}

.map-properties .map-content h4 a {
    color: #2b3d58;
}

.dropzone-design:hover {
    border: 2px dashed #2b3d58;
}


@media (max-width: 992px) {
    .main-header .navbar-expand-lg .navbar-nav .nav-link:hover {
        color: #2b3d58!important;
    }
}

@media (max-width: 768px) {
    .navbar-default .navbar-toggle .icon-bar {
        background-color: #2b3d58;
    }

    .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
        background-color: #2b3d58 !important;
    }

    .main-header .navbar-default .nav > li > a:hover {
        background: #2b3d58;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus, .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
        background-color: #2b3d58 !important;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        background: #eee;
    }
}

@media (min-width: 992px) {
    /*.header-transparent .navbar-expand-lg .navbar-nav .active .nav-link,
    .header-transparent .navbar-expand-lg .navbar-nav .nav-link:hover
    {
        color: #fff !important;
    }

    .header-transparent .navbar-expand-lg .navbar-nav .nav-link {
        color: #fff;
    }

    .header-transparent .link-color {
        color: #fff;
    }*/
}